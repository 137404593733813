import { useReactToPrint } from "react-to-print";
import {
  Button,
  IconButton,
  Loading,
  MiniPrintLayoutFaturamento,
} from "../../../../components";
import { useRef, useState } from "react";
import { notification } from "../../../../services";
import MainPrintLayout from "./MainPrintLayout";
import { useImportContext } from "../../../../contexts/import.context";
import EnvioWhatsappDialog from "./dialogComponents/EnvioWhatsappDialog";
import { voltar } from "../../../../utils";

const ButtonMenu = ({ id, data, onClickCancelarPedido }) => {
  const { initialCollapses } = useImportContext();
  const [isImpressaoA4, setIsImpressaoA4] = useState(true);
  const [openDialogEnvio, setOpenDialogEnvio] = useState(false);
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    pageStyle: `
        @page {
            size: auto;
            margin: 8mm;
        }
    `,
  });

  const escolhaImpressao = async () => {
    const retornoAlerta = await notification.confirmacaoGenericos({
      title: "Selecione um modelo de impressão",
      icon: "info",
      confirmButtonText: `
        <i class="ph-fill ph-article" style="font-size:40px; color: white;"></i> <p style="margin: 0">A4</p>
      `,
      cancelButtonText: `
        <i class="ph-fill ph-scroll" style="font-size:40px; color: white;"></i> <p style="margin: 0">80mm</p>
      `,
      showCancelButton: true,
    });
    setLoading(true);
    if (retornoAlerta.isConfirmed) {
      setIsImpressaoA4(true);
      setTimeout(() => {
        setLoading(false);
        handlePrint();
        if (!id) {
          initialCollapses();
          voltar();
        }
      }, 1000);
      return;
    }
    if (retornoAlerta.dismiss === "cancel") {
      setIsImpressaoA4(false);
      setTimeout(() => {
        setLoading(false);
        handlePrint();
        if (!id) {
          initialCollapses();
          voltar();
        }
      }, 1000);
      return;
    }
    setLoading(false);
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className={!id && "d-none"}>
        <Button
          variant="outlined"
          className="mb-1"
          size="small"
          color="primary"
          onClick={onClickCancelarPedido}
        >
          Cancelar Pedido
        </Button>
        <div className="d-none">
          <div ref={componentRef}>
            {isImpressaoA4 ? (
              <MainPrintLayout data={data} />
            ) : (
              <MiniPrintLayoutFaturamento
                data={data}
                descricaoTipoDocumento="pedido"
              />
            )}
          </div>
        </div>
        <Button
          id="botaoMenuAction"
          onClick={escolhaImpressao}
          size="small"
          sx={{ borderRadius: 50 }}
        >
          <i className="ph-fill ph-printer" style={{ fontSize: 18 }}></i>
        </Button>
        <IconButton
          onClick={() => setOpenDialogEnvio(true)}
          size="small"
          sx={{ marginLeft: 2 }}
        >
          <i
            className="ph-fill ph-whatsapp-logo"
            style={{ fontSize: 18, color: "#48A34C" }}
          ></i>
        </IconButton>
      </div>
      <EnvioWhatsappDialog
        open={openDialogEnvio}
        setOpen={setOpenDialogEnvio}
        data={data}
      />
      <Loading loading={loading} />
    </div>
  );
};

export default ButtonMenu;
