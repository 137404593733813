import { useEffect, useState } from "react";
import {
  /* Button, */ Card,
  CardContent,
  Loading,
} from "../../../../components";
import "../../configuracoes.style.css";
import { AddDispositivoWhatsapp } from "../dialogComponents";
import { notification, AdzapService } from "../../../../services";
import { responseErros } from "../../../../utils";

const IntegracoesTab = ({ openAddDispositivo, setOpenAddDispositivo }) => {
  const adzapService = new AdzapService();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("disconnected");
  const [qrCode, setQrCode] = useState(null);
  const [timeLeft, setTimeLeft] = useState(0);
  const [minutos, setMinutos] = useState(0);
  const [segundos, setSegundos] = useState(0);

  const images = {
    bg: require("../../../../assets/images/bg-whatsapp.png"),
    icon: require("../../../../assets/images/webzap.png"),
  };

  useEffect(() => {
    if (timeLeft <= 0 || status === "connected") return;
    const interval = setInterval(() => {
      const m = Math.floor(timeLeft / 60);
      const s = timeLeft - m * 60;

      setMinutos(m);
      setSegundos(s);

      if (s % 10 === 0) {
        verificaStatus();
      }

      if (m <= 0 && s <= 1) {
        clearInterval(interval);
        verificaStatus(true);
        return;
      }

      setTimeLeft((t) => t - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft]);

  useEffect(() => {
    iniciarInstancia();
  }, []);

  async function iniciarInstancia() {
    setLoading(true);
    const result = await adzapService.iniciarInstancia();
    if (!result.isAxiosError) {
      if (result.data) {
        setQrCode(result.data);
        handleOpenAddDispositivo(result.data);
      } else {
        verificaStatus();
      }
    } else {
      responseErros(result);
    }
    setLoading(false);
  }

  async function verificaStatus(expired = false) {
    const result = await adzapService.statusInstancia(expired);
    if (!result.isAxiosError) {
      const { status: statusInstancia, qrcode } = result.data;
      if (!statusInstancia) return;
      setStatus(statusInstancia);
      if (statusInstancia === "expired" && qrcode) {
        setTimeLeft(120);
        return setQrCode(qrcode);
      }
      if (statusInstancia === "connected" && status !== statusInstancia) {
        setOpenAddDispositivo(false);
        notification.sucessoGenericos("Whatsapp conectado com sucesso!");
      }
    } else {
      responseErros(result);
    }
  }

  function handleCloseAddDispositivo() {
    setOpenAddDispositivo(false);
    setTimeLeft(0);
    setQrCode(null);
    setStatus("disconnected");
  }

  async function handleOpenAddDispositivo(qrCode = null) {
    if (status === "connected") {
      notification.alertaGenericos("WhatsApp já conectado!");
      return;
    }
    if (!qrCode) {
      notification.alertaGenericos("QR Code não encontrado!");
      return;
    }
    setTimeLeft(120);
    setOpenAddDispositivo(true);
  }

  return (
    <Card className="p-2">
      <CardContent
        style={{ background: `url(${images.bg})` }}
        className="d-flex flex-column align-items-center justify-content-center text-center py-5"
      >
        <img
          style={{ width: "20%" }}
          src={images.icon}
          alt="Whatsapp Icon"
          className="me-4"
        />
        {status === "connected" ? (
          <>
            <h4
              className="mt-3 fw-bold text-uppercase"
              style={{ color: "#25d366" }}
            >
              WhatsApp conectado!
            </h4>
            <p className="text-muted">
              A integração com o sistema foi realizada com sucesso.
            </p>
          </>
        ) : (
          <>
            <h4
              className="mt-3 fw-bold text-uppercase"
              style={{ color: "#25d366" }}
            >
              Nenhum número conectado
            </h4>
            <p className="text-muted">
              Para iniciar a integração, adicione um número de telefone ao
              sistema
            </p>
            {/* <Button
              variant="contained"
              className="mt-4 btn btn-primary px-4 py-2"
              onClick={async () => await handleOpenAddDispositivo()}
            >
              Conectar
            </Button> */}
          </>
        )}
      </CardContent>
      <AddDispositivoWhatsapp
        openAddDispositivo={openAddDispositivo}
        setOpenAddDispositivo={setOpenAddDispositivo}
        handleCloseAddDispositivo={handleCloseAddDispositivo}
        qrCode={qrCode}
        minutos={minutos}
        segundos={segundos}
      />
      <Loading loading={loading} />
    </Card>
  );
};
export default IntegracoesTab;
