import * as Yup from "yup";

const condicionalPdfValidator = Yup.object().shape({
  id: Yup.number().required("ID"),
  numeroDocumento: Yup.number().required("Número do Documento"),
  dataEmissao: Yup.date().required("Data Emissão"),
  informacaoAdicional: Yup.string().nullable(),
  desconto: Yup.number().nullable(),
  acrescimo: Yup.number().nullable(),
  despesas: Yup.number().nullable(),
  itens: Yup.array()
    .of(
      Yup.object().shape({
        unidade: Yup.string().nullable(),
        codigo: Yup.string().required("Codigo"),
        quantidade: Yup.number().required("Quantidade"),
        valorUnitario: Yup.number().required("Valor Unitario"),
        descricao: Yup.string().required("Descrição Produto"),
        totalItem: Yup.number().required("Valor Total"),
      })
    )
    .min(1, "incluir ao menos 1 produto"),
  imagemLogo: Yup.string().nullable(),
  razaoSocial: Yup.string().required("Razão Social"),
  fantasia: Yup.string().required("Fantasia"),
  cnpjCpf: Yup.string().required("Cnpj / Cpf - Empresa"),
  email: Yup.string().nullable(),
  telefone: Yup.string().nullable(),
  celular: Yup.string().nullable(),
  enderecoLogradouro: Yup.string().nullable(),
  enderecoLogradouroNumero: Yup.string().nullable(),
  enderecoBairro: Yup.string().nullable(),
  enderecoCidade: Yup.string().nullable(),
  enderecoUF: Yup.string().nullable(),
  vendedor: Yup.string().required("Vendedor"),
  cliente: Yup.object()
    .shape({
      nomeRazaoSocial: Yup.string().required("Razão Social"),
      cnpjCpf: Yup.string().nullable(),
      email: Yup.string().nullable(),
      telefone: Yup.string().nullable(),
      celular: Yup.string().nullable(),
    })
    .nullable(),
  enderecoCliente: Yup.object()
    .shape({
      cep: Yup.string().required("Cep do Cliente"),
      logradouro: Yup.string().required("Logradouro do Cliente"),
      numero: Yup.string().required("Número do Cliente"),
      bairro: Yup.string().required("Bairro do Cliente"),
      complemento: Yup.string().nullable(),
      descricao: Yup.string().nullable(),
      cidade: Yup.string().required("Cidade do Cliente"),
      estado: Yup.string().required("Estado do Cliente"),
    })
    .nullable(),
  totalItens: Yup.number().required("Total dos Itens"),
  totalOrcamento: Yup.number().required("Total do Orçamento"),
});

export default condicionalPdfValidator;
