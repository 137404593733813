import createAxiosConfig from "../config/createAxiosConfig";
import store from "../config/store";
import SiafLiteApiBaseRepository from "./siaf-lite-api-base";
const headers = {
  "Content-Type": "application/json",
};

const axiosConfig = createAxiosConfig(store.baseUrlApiAdzap, headers);

class SiafLiteAdzapRepository extends SiafLiteApiBaseRepository {
  constructor(url) {
    super(url, axiosConfig);
  }
}

export default SiafLiteAdzapRepository;
