import { useState, useEffect, useRef } from "react";
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  DatePicker,
} from "../../../../components";
import {
  MeioPagamentoService,
  BancosCaixasService,
  notification,
  PessoaService,
} from "../../../../services";
import { responseErros, timeoutBuscaAutocomplete } from "../../../../utils";

const FormFiltroAvancado = ({
  valueInput,
  onChangeInput,
  valueDate,
  onChangeDate,
  valueAutocomplete,
  onChangeAutocomplete,
}) => {
  const inputRef = useRef();
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [listaMeiosPagamentos, setListaMeiosPagamentos] = useState([]);
  const [pagadorList, setPagadorList] = useState([]);
  const [listaContas, setListaContas] = useState([]);
  const meioPagamentoService = new MeioPagamentoService();
  const pessoaService = new PessoaService();
  const bancosCaixasService = new BancosCaixasService();
  const listaStatus = [
    {
      label: "Aberto",
      value: "ABERTO",
      cor: "#4FBB53",
    },
    {
      label: "Baixado Parcial",
      value: "BAIXADO_PARCIAL",
      cor: "#EA9D2B",
    },
    {
      label: "Baixado",
      value: "BAIXADO",
      cor: "#004780",
    },
    {
      label: "Cancelado",
      value: "CANCELADO",
      cor: "#DC3545",
    },
  ];

  useEffect(() => {
    buscarMeiosPagamentos();
    buscarContas();
  }, []);

  const buscarPagador = async (filtros) => {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      ativado: true,
    };
    pessoaService.getAll(filtro).then((res) => {
      if (!res.isAxiosError) {
        setPagadorList(res.data);
      } else {
        responseErros(res);
      }
    });
    setLoadingAutoComplete(false);
  };

  const buscarMeiosPagamentos = () => {
    const filtros = {
      nonPaginated: true,
    };
    meioPagamentoService.getAll(filtros).then((res) => {
      if (!res.isAxiosError) {
        setListaMeiosPagamentos(res.data);
      } else {
        const error = res.response ? res.response.data.error : res.message;
        notification.erroValidacao(error);
      }
    });
  };

  const buscarContas = () => {
    const filtros = {
      nonPaginated: true,
    };
    bancosCaixasService.getAll(filtros).then((res) => {
      if (!res.isAxiosError) {
        setListaContas(res.data);
      } else {
        const error = res.response ? res.response.data.error : res.message;
        notification.erroValidacao(error);
      }
    });
  };

  const onInputChangePessoa = (event) => {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      if (event.target?.value?.length >= 2) {
        const filtros = {
          nomeRazaoSocial: event.target.value,
          apelidoFantasia: event.target.value,
        };
        setLoadingAutoComplete(true);
        timeoutBuscaAutocomplete(buscarPagador(filtros));
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="numeroTitulo"
          name="numeroTitulo"
          label="Nº Título"
          fullWidth
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("numeroTitulo")}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          id="status"
          name="status"
          value={valueInput("status")}
          label="Status"
          fullWidth
          onChange={(event) => onChangeInput(event)}
        >
          {listaStatus.map((status, index) => (
            <MenuItem key={index} value={status.value}>
              <i
                className="ph-fill ph-circle"
                style={{ color: `${status.cor}` }}
              ></i>
              &nbsp;{status.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Select
          id="tipo"
          name="tipo"
          value={valueInput("tipo")}
          label="Tipo"
          onChange={(event) => onChangeInput(event)}
          fullWidth
        >
          <MenuItem value={"TITULO_NORMAL"}>Título Normal</MenuItem>
          <MenuItem value={"NOTA_CREDITO"}>Nota Crédito</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="pagadorId"
          name="pagadorId"
          options={pagadorList}
          autoHighlight
          getOptionLabel={(option) => {
            if (option.nomeRazaoSocial) {
              if (option.codigo) {
                return `${option.codigo} - ${option.nome}`;
              }
              return option.nomeRazaoSocial;
            }
          }}
          getOptionSelected={(option, value) =>
            option?.nomeRazaoSocial === value?.nomeRazaoSocial
          }
          loading={loadingAutoComplete}
          loadingText="Carregando"
          noOptionsText="Digite e pressione Enter"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Pagador"
              variant="outlined"
              onKeyDown={onInputChangePessoa}
              inputRef={inputRef}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          onChange={(e, value) => onChangeAutocomplete("pagadorId", value)}
          value={valueAutocomplete(pagadorList, "pagadorId")}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="bancoCaixaId"
          name="bancoCaixaId"
          options={listaContas}
          noOptionsText="Sem opções"
          autoHighlight
          getOptionLabel={(option) => (option.nome ? option.nome : "")}
          renderInput={(params) => (
            <TextField {...params} label="Conta" variant="outlined" />
          )}
          onChange={(e, value) => onChangeAutocomplete("bancoCaixaId", value)}
          value={valueAutocomplete(listaContas, "bancoCaixaId")}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="meioPagamentoId"
          name="meioPagamentoId"
          options={listaMeiosPagamentos}
          noOptionsText="Sem opções"
          autoHighlight
          getOptionLabel={(option) =>
            option.descricao ? option.descricao : ""
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Meio de Pagamento"
              variant="outlined"
            />
          )}
          onChange={(e, value) =>
            onChangeAutocomplete("meioPagamentoId", value)
          }
          value={valueAutocomplete(listaMeiosPagamentos, "meioPagamentoId")}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataLancamentoStart"
          name="dataLancamentoStart"
          label="Data Lançamento De"
          format="dd/MM/yyyy"
          variant="outlined"
          value={valueDate("dataLancamentoStart")}
          onChange={(date) => onChangeDate("dataLancamentoStart", date)}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataLancamentoEnd"
          name="dataLancamentoEnd"
          label="Data Lançamento Até"
          format="dd/MM/yyyy"
          variant="outlined"
          value={valueDate("dataLancamentoEnd")}
          onChange={(date) => onChangeDate("dataLancamentoEnd", date)}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataVencimentoStart"
          name="dataVencimentoStart"
          label="Data Vencimento De"
          format="dd/MM/yyyy"
          variant="outlined"
          value={valueDate("dataVencimentoStart")}
          onChange={(date) => onChangeDate("dataVencimentoStart", date)}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataVencimentoEnd"
          name="dataVencimentoEnd"
          label="Data Vencimento Até"
          format="dd/MM/yyyy"
          variant="outlined"
          value={valueDate("dataVencimentoEnd")}
          onChange={(date) => onChangeDate("dataVencimentoEnd", date)}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
