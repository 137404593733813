import {
  CardHeader,
  Dialog,
  DialogContent,
  Grid,
  Loading,
  MaskedInput,
  IconButton,
  Button,
} from "../../../../../components";
import { useEffect, useRef, useState } from "react";
import { notification, AdzapService } from "../../../../../services";
import { ModeloNfce, ModeloNfe } from "../modelos-impressao";
import MainPrint from "../main-print-layout";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const EnvioWhatsappNfeDialog = ({ open, setOpen, nfSaida, dadosImpressao }) => {
  const componentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [celular, setCelular] = useState("");
  const [erroCelular, setErroCelular] = useState(false);
  const adzapService = new AdzapService();

  useEffect(() => {
    if (open && nfSaida?.cliente?.id) setCelular(nfSaida?.cliente?.celular);
  }, [open]);

  const handleInputChange = (event) => {
    setErroCelular(false);
    setCelular(event.target.value);
  };

  function handleCloseDialog() {
    setLoading(false);
    setCelular("");
    setOpen(false);
  }

  async function handleSubmit() {
    if (!celular || celular.replace(/\D/g, "").length < 11) {
      notification.erroGenericos("Informe um número de celular válido");
      setErroCelular(true);
      return;
    }
    setLoading(true);
    try {
      const canvas = await html2canvas(componentRef.current, {
        scale: dadosImpressao?.modelo === "55" ? 1 : 2,
        logging: false,
        useCORS: true,
      });

      const margin = 20;
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const pdf = new jsPDF({
        orientation: imgWidth > imgHeight ? "landscape" : "portrait",
        unit: "px",
        format: [imgWidth + margin * 2, imgHeight + margin * 2],
        compressPdf: true,
      });

      pdf.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        margin,
        margin,
        imgWidth,
        imgHeight,
        "impressao",
        "FAST"
      );

      const pdfBase64 = pdf.output("datauristring").split(",")[1];
      const docName =
        nfSaida?.chaveAcesso || dadosImpressao?.nfeChave
          ? `DANFE-${nfSaida?.chaveAcesso || dadosImpressao?.nfeChave}.pdf`
          : `DOC-SERIE-${nfSaida.serieDocumento}/NUM-${nfSaida.numeroDocumento}.pdf`;

      await enviarPdf({
        file: pdfBase64,
        docName: docName,
      });
    } catch (error) {
      console.error("Erro ao gerar PDF:", error);
      notification.erroGenericos("Falha ao gerar o documento");
      setLoading(false);
    }
  }

  async function enviarPdf(dadosArquivo) {
    try {
      const result = await adzapService.enviarMensagem({
        number: celular,
        type: "document",
        file: dadosArquivo.file,
        docName: dadosArquivo.docName,
        text: "Segue documento solicitado!",
      });

      if (!result.isAxiosError) {
        handleCloseDialog();
        notification.sucessoGenericos("Documento enviado com sucesso!");
      }
    } catch (error) {
      notification.erroGenericos("Falha no envio do documento");
    } finally {
      setLoading(false);
    }
  }

  function modeloImpressao() {
    switch (dadosImpressao?.modelo) {
      case "55":
        return <ModeloNfe data={dadosImpressao} />;
      case "65":
        return <ModeloNfce data={dadosImpressao} />;
      default:
        return <MainPrint data={nfSaida} />;
    }
  }

  return (
    <>
      <div
        style={{
          position: "absolute",
          left: "-9999px",
          width: "100%",
          maxWidth: "800px",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        <div
          ref={componentRef}
          style={{
            width: "100%",
            display: "inline-block",
            backgroundColor: "white",
          }}
        >
          {modeloImpressao()}
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={() => handleCloseDialog()}
      >
        <CardHeader title="Envio Whatsapp" className="m-2" />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MaskedInput
                id="celular"
                name="celular"
                label="Celular"
                mask="(99) 9 9999-9999"
                onChange={handleInputChange}
                margin="none"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <IconButton disabled={true}>
                      <i
                        className="ph-fill ph-device-mobile"
                        style={{ fontSize: 20 }}
                      ></i>
                    </IconButton>
                  ),
                }}
                error={erroCelular}
                value={celular || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                Enviar Documento
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <Loading loading={loading} />
      </Dialog>
    </>
  );
};

export default EnvioWhatsappNfeDialog;
