import shortid from "shortid";
import { formatDate } from "../../../../utils";
import { calculoDesconto, totalOrcamento } from "../addOrcamentos";

export class Orcamento {
  dataEmissao = null;
  numeroDocumento = null;
  serieDocumento = null;
  operacaoFiscalId = null;
  pessoaVendedorId = null;
  pessoaClienteId = null;
  pessoaProficionalId = null;
  orcamentoEnderecoCliente = null;
  condicaoPagamentoId = null;
  meioPagamentoId = null;
  desconto = null;
  acrescimo = null;
  despesas = null;
  informacaoAdicional = null;
  itens = null;
  orcamentoEnderecoCliente = null;
  status = null;

  constructor(orcamento, id) {
    if (id) {
      this.operacaoFiscalId = orcamento.operacaoFiscalId;
      this.numeroDocumento = orcamento.numeroDocumento;
      this.serieDocumento = orcamento.serieDocumento;
      this.pessoaVendedorId = orcamento.pessoaVendedorId;
      this.pessoaClienteId = orcamento.pessoaClienteId;
      this.pessoaProficionalId = orcamento.pessoaProficionalId;
      this.orcamentoEnderecoCliente = orcamento.orcamentoEnderecoCliente;
      this.informacaoAdicional = orcamento.informacaoAdicional;
      this.itens = orcamento.itens?.length
        ? orcamento.itens.map((item) => new OrcamentoItens(item))
        : [];
      this.condicaoPagamentoId = orcamento.condicaoPagamentoId;
      this.meioPagamentoId = orcamento.meioPagamentoId;
      this.status = orcamento.status;
    }
    this.dataEmissao = orcamento.dataEmissao;
    this.desconto = orcamento.desconto;
    this.acrescimo = orcamento.acrescimo;
    this.despesas = orcamento.despesas;
  }

  setHandleChangeInput(name, value) {
    this[name] = value;
  }

  setHandleChangeDate(name, date) {
    this[name] = date != "Invalid Date" ? formatDate.toSend(date) : undefined;
  }

  setHandleChangeAutoComplete(name, value) {
    this[name] = value ? value.id : null;
    if (name === "operacaoFiscalId") {
      this.setOperacaoFiscal(value);
    }
  }

  setChangeItens(produtos) {
    this.itens = produtos?.length
      ? produtos.map((produto) => new OrcamentoItens(produto))
      : [];
    this.descontoPorcentagem = calculoDesconto(produtos, this, false);
    this.totalPedido = totalOrcamento(this, produtos);
  }

  setEnderecoCliente(enderecoAlterado, enderecoCliente) {
    if (this.pessoaClienteId) {
      const endereco = enderecoAlterado || enderecoCliente;
      if (endereco) {
        this.pedidoEnderecoCliente = {
          ...endereco,
          pessoaId: this.pessoaClienteId,
        };
        return;
      }
    }
    this.pedidoEnderecoCliente = null;
  }

  setOperacaoFiscal(operacaoFiscal) {
    this.operacaoFiscalId = operacaoFiscal.id;
    this.serieDocumento = operacaoFiscal?.serieDocumento;
    this.modeloDocumento = operacaoFiscal?.modeloDocumento;
  }

  setVendedorObrigatorioPermissao(permissao) {
    this.vendedorObrigatorio = permissao;
  }

  setDescontoPorcentagem(listaPedidosProdutos, porcentagem = false) {
    this[porcentagem ? "descontoPorcentagem" : "desconto"] = calculoDesconto(
      listaPedidosProdutos,
      this,
      false
    );
  }
}

class OrcamentoItens {
  produtoId = null;
  unidade = null;
  quantidade = null;
  codigo = null;
  valorUnitario = null;
  ncm = null;
  tributosIcmsOrigem = null;

  constructor(itens) {
    this.id = itens.id ? itens.id : shortid.generate();
    this.produtoId = itens.produtoId;
    this.unidade = itens.unidade;
    this.quantidade = itens.quantidade;
    this.codigo = itens.codigo;
    this.valorUnitario = itens.valorUnitario;
    this.ncm = itens.ncm;
    this.tributosIcmsOrigem = itens.tributosIcmsOrigem;
  }
}
