import {
  CardHeader,
  Dialog,
  DialogContent,
  Grid,
  Loading,
  MaskedInput,
  IconButton,
  Button,
} from "../../../../../components";
import { useEffect, useState } from "react";
import {
  notification,
  PedidosService,
  AdzapService,
} from "../../../../../services";
import { responseErros } from "../../../../../utils";
import { pdfPedidoValidator } from "../../middlewares";
import { InputErros } from "../../../../../helpers";

const EnvioWhatsappDialog = ({ open, setOpen, data }) => {
  const [loading, setLoading] = useState(false);
  const [celular, setCelular] = useState("");
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const pedidosService = new PedidosService();
  const adzapService = new AdzapService();

  useEffect(() => {
    if (open && data.cliente?.id) setCelular(data.cliente?.celular);
  }, [open]);

  const handleInputChange = (event) => {
    setCelular(event.target.value);
  };

  function handleCloseDialog() {
    setLoading(false);
    setCelular("");
    setOpen(false);
  }

  const aspectRatioLogoEmpresa = () => {
    const img = document.getElementById("whats");
    const aspectRatio = (img.naturalWidth || 1) / (img.naturalHeight || 1);
    return aspectRatio <= 1.5 ? 100 : 20;
  };

  async function handleSubmit() {
    setLoading(true);
    pdfPedidoValidator
      .validate(data, { abortEarly: false })
      .then(async () => {
        const aspectRatio = aspectRatioLogoEmpresa();
        const result = await pedidosService.pdf({
          ...data,
          aspectRatio,
        });
        if (!result.isAxiosError) {
          if (result.data) {
            await enviarPdf(result.data);
          }
        } else {
          setLoading(false);
          responseErros(result);
        }
      })
      .catch((err) => {
        inputErros.set(err);
        setLoading(false);
      });
  }

  async function enviarPdf(dadosArquivo) {
    adzapService
      .enviarMensagem({
        number: celular,
        type: "document",
        file: dadosArquivo.file,
        docName: dadosArquivo.docName,
        text: "Segue pedido solicitado!",
      })
      .then((result) => {
        if (!result.isAxiosError) {
          handleCloseDialog();
          notification.sucessoGenericos("Pedido enviado com sucesso!");
          setLoading(false);
        }
      });
  }

  return (
    <>
      <img
        id="whats"
        className="d-none me-3"
        src={data?.imagemLogo}
        alt="logo da empresa"
      />
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={() => handleCloseDialog()}
      >
        <CardHeader title="Envio Whatsapp" className="m-2" />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MaskedInput
                id="celular"
                name="celular"
                label="Celular"
                mask="(99) 9 9999-9999"
                onChange={handleInputChange}
                margin="none"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <IconButton disabled={true}>
                      <i
                        className="ph-fill ph-device-mobile"
                        style={{ fontSize: 20 }}
                      ></i>
                    </IconButton>
                  ),
                }}
                value={celular ? celular : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                Enviar Pedido
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <Loading loading={loading} />
      </Dialog>
    </>
  );
};

export default EnvioWhatsappDialog;
