const constantes = {
  nfPagamentosList: [
    { value: "fpDinheiro", label: "Dinheiro" },
    { value: "fpCheque", label: "Cheque" },
    { value: "fpCartaoCredito", label: "Cartão Crédito" },
    { value: "fpCartaoDebito", label: "Cartão Débito" },
    { value: "fpCreditoLoja", label: "Crédito Loja" },
    { value: "fpValeAlimentacao", label: "Vale Alimentação" },
    { value: "fpValeRefeicao", label: "Vale Refeição" },
    { value: "fpValePresente", label: "Vale Presente" },
    { value: "fpValeCombustivel", label: "Vale Combustível" },
    { value: "fpDuplicataMercantil", label: "Duplicata Mercantil" },
    { value: "fpBoletoBancario", label: "Boleto" },
    { value: "fpDepositoBancario", label: "Depósito" },
    { value: "fpPagamentoInstantaneoPIXEstatico", label: "Pix Estático" },
    { value: "fpPagamentoInstantaneoPIXDinamico", label: "Pix Dinâmico" },
    { value: "fpTransferenciabancaria", label: "Transferência Bancária" },
    { value: "fpProgramadefidelidade", label: "CashBack/Crédito Virtual" },
    { value: "fpSemPagamento", label: "Sem Pagamento" },
    { value: "fpOutro", label: "Outros" },
  ],
};

export default constantes;
