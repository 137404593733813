import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "../../../../components";

function AddDispositivoWhatsapp({
  openAddDispositivo,
  setOpenAddDispositivo,
  handleCloseAddDispositivo,
  qrCode,
  minutos,
  segundos,
}) {
  const formatTime = (t) => (t < 10 ? "0" + t : t);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={openAddDispositivo}
      onClose={() => setOpenAddDispositivo(false)}
    >
      <DialogContent>
        <CardHeader title="Conecte o dispositivo" />

        <Grid container spacing={3} alignItems="center">
          <Grid item xs={7}>
            <List>
              <ListItem>
                <ListItemText primary="1. Abra o WhatsApp no celular;" />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      {`2. Clique em "Mais opções"`}{" "}
                      <i
                        className="ph-bold ph-dots-three-vertical"
                        style={{ fontSize: "1.1rem" }}
                      ></i>{" "}
                      {`no Android, ou em
                        "Configurações"`}{" "}
                      <i
                        className="ph-fill ph-gear-six"
                        style={{ fontSize: "1.1rem" }}
                      ></i>{" "}
                      no iPhone;
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="3. Toque em 'Dispositivos conectados' e, em seguida, em 'Conectar dispositivo';" />
              </ListItem>
              <ListItem>
                <ListItemText primary="4. Aponte o celular para escanear o QR Code." />
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            xs={5}
            className="d-flex flex-column justify-content-end align-items-center text-center"
          >
            <img
              src={qrCode}
              alt="QR Code"
              style={{
                width: "225px",
                height: "225px",
                objectFit: "contain",
              }}
            />
            <div>
              <span>{formatTime(minutos)}</span> : <span>{formatTime(segundos)}</span>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="me-3 mb-3">
        <Button
          variant="contained"
          color="primary"
          onClick={handleCloseAddDispositivo}
        >
          fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddDispositivoWhatsapp;
