import AdzapRepository from "../../external/siaf-lite-adzap";
import SessionService from "../session.service";
class AdzapService {
  constructor() {
    this.sessionService = new SessionService();
  }

  async iniciarInstancia(body) {
    const { cnpjCpf } = this.sessionService.getEmpresa();
    return new AdzapRepository("/init-instance").post({
      ...body,
      sistema: "SIAFLITE",
      cnpjCpf,
    });
  }

  async statusInstancia(expired = false) {
    const { cnpjCpf } = this.sessionService.getEmpresa();
    return new AdzapRepository("/status-connection").get({ cnpjCpf, expired });
  }

  async enviarMensagem(body) {
    const { cnpjCpf } = this.sessionService.getEmpresa();
    return new AdzapRepository("/send-message").post({ ...body, cnpjCpf });
  }
}

export default AdzapService;
